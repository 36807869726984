/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PublicLayout from './components/layout/PublicLayout';
import DefaultLayout from './components/layout/DefaultLayout';
import { appMountedAction } from './redux/AppActions';
import { refreshAuthAction } from './modules/auth/AuthActions';
import AppRoute from './components/routing/AppRoute';
import { AppRouter } from './components/routing/AppRouter';

function AppContainer(props: {
  appMounted: () => void;
  refreshAuth: () => void;
  ready: any;
}) {
  useEffect(() => {
    props.appMounted();
    props.refreshAuth();
  }, []);

  if (!props.ready) return null;

  const InnerRouting = (props: any) => getInnerPaths(props);

  const getInnerPaths = (props: { match: { path: any } }) => {
    let url = props.match.path;
    url = url.replace(/\/$/, '');

    return (
      <Switch>
        <AppRoute
          path={`${url}/login`}
          component={PublicLayout}
          unAuthenticatedOnly
        />
        <AppRoute
          path={`${url}/forgot-password`}
          component={PublicLayout}
          unAuthenticatedOnly
        />

        <AppRoute path="" component={DefaultLayout} authenticatedOnly />
      </Switch>
    );
  };

  return (
    <AppRouter>
      <div className="App">
        <Switch>
          <Route path="/" component={InnerRouting} />
        </Switch>
      </div>
    </AppRouter>
  );
}

const mapDispatchToProps = (
  dispatch: (arg0: { type: string; payload?: null }) => any
) => {
  return {
    appMounted: () => {
      return dispatch(appMountedAction());
    },
    refreshAuth: () => {
      return dispatch(refreshAuthAction());
    },
  };
};

const mapStateToProps = (state: { ready: any }) => {
  return {
    ready: state.ready,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
