/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import FormHandler from '../../../../../components/form/FormHandler';
import InputField from '../../../../../components/form/InputField';
import FormSubmitWrapper from '../../../../../components/form/FormSubmitWrapper';
import { AppButton } from '../../../../../components/AppButton';
import OTPVerifyCodeModel from '../model/OTPVerifyCodeModel';
import Api from '../../../../../api/Api';
import ToastHelper from '../../../../../components/ToastHelper';

class RdxOTPConfirmationPage extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new OTPVerifyCodeModel({
        hash:
          props.location.state?.authorization['3ds_authentication_request']
            .hash || '',
        token: props.token,
        input: '',
      }),
      {
        timer: 59,
        resendLoading: false,
        textMessage: '',
        redirect: false,
        submitLoading: false,
      }
    );
    this.timer = 0;
  }

  componentDidMount() {
    this.myInterval = setInterval(() => {
      const { timer } = this.state;

      if (timer > 0) {
        this.setState(() => ({
          timer: timer - 1,
        }));
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  onBeforeSubmit() {
    this.setState({ submitLoading: true });
  }

  onSubmitError() {
    this.setState({ submitLoading: false }, () => {
      this.setState((prevState) => ({
        record: {
          ...prevState.record,
          input: '',
        },
      }));
    });
  }

  async onSubmitSuccess(data) {
    if (data.success) {
      setTimeout(() => {
        clearInterval(this.myInterval);
      }, 500);
      if (data.success && data.data.nextState.name === 'SUCCESSFUL_SCA') {
        await Api.call(
          `authenticator/v1/authorizations/${
            this.props.location.state &&
            this.props.location.state.authorization.id
          }/update`,
          {
            data: {
              confirm: true,
              authorizationCode:
                this.props.location.state?.authorization[
                  '3ds_authentication_request'
                ].hash,
            },
          }
        ).then((res) => {
          if (res.success) {
            ToastHelper.show(
              res.message ||
                `Authentication Successful. Please return to the merchant screen.`,
              'success'
            );
            setTimeout(() => {
              this.setState(
                {
                  submitLoading: false,
                },
                () => {
                  if (res.data.callback_url) {
                    window.location.replace(res.data.callback_url);
                  }
                  if (res.data.merchantAppRedirectURL) {
                    window.location.replace(res.data.merchantAppRedirectURL);
                  }
                  this.props.history.push('/');
                }
              );
            }, 100);
          } else {
            ToastHelper.show(res.message, 'error');
            this.setState((prevState) => ({
              record: {
                ...prevState.record,
                input: '',
              },
              submitLoading: false,
            }));
          }
        });
      }
    } else {
      ToastHelper.show(
        'Something went wrong, please try again later.',
        'error'
      );
      this.setState((prevState) => ({
        record: {
          ...prevState.record,
          input: '',
        },
        submitLoading: false,
      }));
    }
  }

  async _resendSmsCode() {
    this.setState({ resendLoading: true });

    const result = await Api.call('otp/resend', {
      hash: this.props.location.state?.authorization[
        '3ds_authentication_request'
      ].hash,
    });

    if (result) {
      this.setState({ resendLoading: false }, () => {
        clearInterval(this.myInterval);
        this.setState(
          {
            timer: 59,
          },
          () => {
            this.myInterval = setInterval(() => {
              const { timer } = this.state;

              if (timer > 0) {
                this.setState(() => ({
                  timer: timer - 1,
                }));
              }
            }, 1000);
          }
        );
        return true;
      });
    }
  }

  _resetForm() {
    const { formHandler } = this;

    const subText = 'Please enter it below: ';

    return (
      <div className="content-container">
        <h2 className="bold-title">
          We&apos;ve sent a One Time Password to your registered
          {this.props.location.state &&
          this.props.location.state.channel === 'mobile'
            ? ' mobile phone number.'
            : ' email address.'}
        </h2>
        <h2 className="bold-title">{subText}</h2>

        <div
          style={{
            width: '100%',
            maxWidth: 310,
            minWidth: 310,
            paddingTop: 20,
          }}
        >
          <FormSubmitWrapper
            formHandler={formHandler}
            buttonClass="gold-button"
            to="/"
            invertedBtn
            buttonText="Submit"
            customLoading={this.state.submitLoading}
          >
            <div className="login-input-wrapper">
              <InputField
                className="input-group"
                materialProps={{
                  fullWidth: true,
                }}
                name="input"
                type="number"
                label="One Time Password"
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.state.record.input}
                handler={formHandler}
              />
            </div>
          </FormSubmitWrapper>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p style={{ textAlign: 'center', color: 'rgb(234, 150, 0)' }}>
            {this.state.textMessage || ' '}
          </p>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    return (
      <div className="info-container">
        {this._resetForm()}

        <div className="content-container">
          <div style={{ width: '100%', marginTop: 50 }}>
            {this.state.timer > 0 ? (
              <p style={{ textAlign: 'center' }}>
                You can resend the one time password in 00:
                {this.state.timer < 10
                  ? `0${this.state.timer}`
                  : this.state.timer}
              </p>
            ) : (
              <div className="btn-info-holder">
                <AppButton
                  isLoading={this.state.resendLoading}
                  onClick={() => this._resendSmsCode()}
                  style={{ maxWidth: 500 }}
                >
                  Resend one time password
                </AppButton>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(RdxOTPConfirmationPage);
