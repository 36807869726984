/* eslint-disable import/no-extraneous-dependencies */
import URL from 'url-parse';
import ConfigModel from '../model/ConfigModel';

const Config = {
  env: process.env.REACT_APP_ENVIRONMENT,
  url: process.env.REACT_APP_URL || '',
};

const env =
  Config.env ||
  (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'dev'
    : 'live');

const publicUrl = new URL(Config.url).origin;

const common = {
  publicUrl,
};

const configuration = {
  ...common,
  ...Config,
};

const config = {
  env,

  // Preloaded configuration
  ...configuration,

  /**
   * Load configuration, first from db, then from API
   */
  async load() {
    try {
      // Load from network
      await this.loadFromNetwork();
    } catch (e) {
      console.log('Config Load Error', e);
    }
  },

  /**
   * Load from network
   */
  async loadFromNetwork() {
    // Get from network
    const result = await new ConfigModel().fetch();

    if (result) {
      // Merge
      this.merge(result);
    }
  },

  /**
   * Merge data with current object
   *
   * @param {Object} result
   */
  async merge(result) {
    Object.assign(this, result);
    new ConfigModel().dispatchConfigUpdate(this);
  },
};

export default config;
