/* eslint-disable consistent-return */
import React from 'react';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import Api from '../../../../../api/Api';
import { AppButton } from '../../../../../components/AppButton';
import ToastHelper from '../../../../../components/ToastHelper';

class WaitForSca extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'in_progress',
      redirect: false,
    };
  }

  componentDidMount() {
    this._triggerPooling();
  }

  componentWillUnmount() {
    if (this.poolTimeout) {
      clearTimeout(this.poolTimeout);
    }
  }

  async _triggerPooling() {
    const { scaHash } = this.props.location.state;

    const response = await Api.call('sca/check', {
      scaHash,
    });

    if (response.data.status === 'confirmed') {
      this.setState(
        {
          status: response.data.status,
        },
        () => {
          ToastHelper.show('Authorization successful.', 'success');
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
        }
      );
    }

    this.setState({
      status: response.data.status,
    });

    if (response.data.status === 'in_progress') {
      this.poolTimeout = setTimeout(() => {
        this._triggerPooling();
      }, 1500);
    }
  }

  _getInProgressElement() {
    const { authorizationKey } = this.props.location.state;
    return (
      <div>
        <h2 style={{ textAlign: 'center', paddingBottom: 30 }}>
          Authorize this action on your registered device.
        </h2>
        <p style={{ textAlign: 'left' }}>
          You should receive a push notification to your registered mobile
          device to authorize this action. If you do not receive a push
          notification please go to the “Pending Authorizations” section to
          verify.
        </p>
        <p style={{ textAlign: 'center', padding: '20px 0' }}>
          Authorization code:{' '}
          <p style={{ fontWeight: 'bold', fontSize: 30, padding: '10px 0' }}>
            {authorizationKey}
          </p>
          <p style={{ fontSize: 14 }}>
            Please note: You should only approve the authorization code if you
            see the same numbers on your mobile device.
          </p>
        </p>
        <p style={{ textAlign: 'left', paddingBottom: 50 }}>
          If you are having trouble authorizing this action, you can contact our
          support team for questions, suggestions or bug reporting at{' '}
          <a href="mailto:support@bobofin.com">support@bobofin.com</a>
        </p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <AppButton
            type="button"
            onClick={() =>
              this.props.onBack
                ? this.props.onBack()
                : this.props.history.goBack()
            }
          >
            Back
          </AppButton>
        </div>
      </div>
    );
  }

  _getConfirmedElement() {
    return (
      <div>
        <h2 style={{ textAlign: 'center', paddingBottom: 30 }}>
          Authorization attempt approved.
        </h2>
        <p style={{ textAlign: 'center' }}>please wait... </p>
      </div>
    );
  }

  _getDeniedElement() {
    return (
      <div>
        <h2 style={{ textAlign: 'center', paddingBottom: 30 }}>
          Authorization attempt has been denied!
        </h2>
        <p style={{ textAlign: 'left', paddingBottom: 50 }}>
          If you are having trouble authorizing this action, you can contact our
          support team for questions, suggestions or bug reporting at{' '}
          <a href="mailto:support@bobofin.com">support@bobofin.com</a>
        </p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <AppButton
            type="button"
            onClick={() =>
              this.props.onBack
                ? this.props.onBack()
                : this.props.history.goBack()
            }
          >
            Back
          </AppButton>
        </div>
      </div>
    );
  }

  _getExpiredElement() {
    return (
      <div>
        <h2 style={{ textAlign: 'center', paddingBottom: 30 }}>
          Authorization attempt has expired!
        </h2>
        <p style={{ textAlign: 'left', paddingBottom: 50 }}>
          If you are having trouble authorizing this action, you can contact our
          support team for questions, suggestions or bug reporting at{' '}
          <a href="mailto:support@bobofin.com">support@bobofin.com</a>
        </p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <AppButton
            type="button"
            onClick={() =>
              this.props.onBack
                ? this.props.onBack()
                : this.props.history.goBack()
            }
          >
            Back
          </AppButton>
        </div>
      </div>
    );
  }

  render() {
    let element = null;

    switch (this.state.status) {
      case 'confirmed':
        element = this._getConfirmedElement();
        break;

      case 'denied':
        element = this._getDeniedElement();
        break;

      case 'expired':
        element = this._getExpiredElement();
        break;

      default:
        element = this._getInProgressElement();
    }

    if (this.state.redirect) {
      return <Redirect to="/" />;
    }

    return <div className="info-container">{element}</div>;
  }
}

export default withRouter(WaitForSca);
