import React from 'react';
import { Link } from 'react-router-dom';
import AppList from '../../../../../components/AppList';
import ConsentModel from '../model/ConsentModel';

function ConsentList() {
  const _renderConsentItem = (item: {
    id: string;
    tppName: string;
    status: string;
    sessionSecret: string;
  }) => {
    return (
      <Link
        key={item.id}
        to={`/consent-details/${item.id}`}
        onClick={() => {
          if (item.sessionSecret) {
            window.localStorage.setItem('CONSENT_HASH', item.sessionSecret);
          }
        }}
        style={{
          textDecoration: 'none',
        }}
      >
        <div className="consent-item">
          <p style={{ fontSize: 16 }}>{item.tppName}</p>
          <p style={{ fontSize: 16 }}>{item.status}</p>
          <span>Details</span>
        </div>
      </Link>
    );
  };

  return (
    <ul className="list-ul">
      <AppList
        model={ConsentModel}
        params={{
          limit: 6,
          order: [
            {
              field: 'id',
              direction: 'desc',
            },
          ],
        }}
        renderWrapper={(items: any) => <ul style={{ padding: 0 }}>{items}</ul>}
        renderItem={(item: any) => _renderConsentItem(item)}
        renderNoItems={() => (
          <div style={{ margin: '20px 0' }} className="no-items">
            <em>A consent list will be displayed here</em>
          </div>
        )}
        paginate
      />
    </ul>
  );
}

export default ConsentList;
