/* eslint-disable import/no-extraneous-dependencies */
import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import ConsentDetails from '../../modules/dashboard/components/psd2-consents/pages/ConsentDetails';
import PaymentDetails from '../../modules/dashboard/components/psd2-consents/pages/payments/PaymentDetails';
import RdxPaymentDetails from '../../modules/dashboard/components/psd2-consents/pages/payments/RdxPaymentDetails';
import AppTopBar from './components/AppTopBar';
import { AppRoute } from '../routing/AppRoute';
import Dashboard from '../../modules/dashboard/pages/Dashboard';
import OTPConfirmationPage from '../../modules/dashboard/components/psd2-consents/pages/OTPConfirmationPage';
import WaitForSca from '../../modules/dashboard/components/psd2-consents/pages/WaitForSca';
import RdxOTPConfirmationPage from '../../modules/dashboard/components/psd2-consents/pages/RdxOTPConfirmationPage';

class DefaultLayout extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      collapseSideBar: false,
    };
  }

  componentDidUpdate(prevProps: { location: any }) {
    window.scrollTo(0, 0);

    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    this.setState({
      collapseSideBar: false,
    });
  }

  _getInnerElements() {
    const currentUrl = this.props.match.url;

    return (
      <Switch>
        <AppRoute
          path={`${currentUrl}rdx-payment-details/:id`}
          component={RdxPaymentDetails}
        />
        <AppRoute
          path={`${currentUrl}payment-details/:id`}
          component={PaymentDetails}
        />
        <AppRoute
          path={`${currentUrl}consent-details/:id`}
          component={ConsentDetails}
        />
        <AppRoute path={`${currentUrl}wait-for-sca`} component={WaitForSca} />
        <AppRoute
          path={`${currentUrl}otp-code-verification`}
          component={OTPConfirmationPage}
        />
        <AppRoute
          path={`${currentUrl}rdx-otp-code-verification`}
          component={RdxOTPConfirmationPage}
        />

        <AppRoute exact path={`${currentUrl}`} component={Dashboard} />
      </Switch>
    );
  }

  /**
   * Render default layout
   *
   * @returns {XML}
   */
  render() {
    if (!this.props.profile) return null;

    const innerElements = this._getInnerElements();

    if (this.state.collapseSideBar) {
      $('body').addClass('noscroll');
      $('html').addClass('noscroll');
    } else {
      $('body').removeClass('noscroll');
      $('html').removeClass('noscroll');
    }

    return (
      <div style={{ position: 'relative' }}>
        <AppTopBar />

        <div className="inner">
          <div className="dashboard-main">{innerElements}</div>

          <div className="clear" />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: { auth: { profile: any } }) => {
  return {
    profile: state.auth.profile,
  };
};

export default connect(mapStateToProps)(DefaultLayout);
