/* eslint-disable import/no-mutable-exports */
import logger from 'redux-logger';
import { applyMiddleware, createStore, combineReducers } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import qs from 'qs';
import appReducers from './AppReducers';
import appSagas from './AppSagas';
import Config from '../config';

function createSagaInjector(runSaga, rootSaga) {
  // Create a dictionary to keep track of injected sagas
  const injectedSagas = new Map();

  const isInjected = (key) => injectedSagas.has(key);

  const injectSaga = (key, saga) => {
    // We won't run saga if it is already injected
    if (isInjected(key)) return;

    // Sagas return task when they executed, which can be used
    // to cancel them
    const task = runSaga(saga);

    // Save the task if we want to cancel it in the future
    injectedSagas.set(key, task);
  };

  // Inject the root saga as it a staticlly loaded file,
  injectSaga('root', rootSaga);

  return injectSaga;
}

// export const history = createHistory();
export const history = createBrowserHistory();

const appendQueryToLocation = () => {
  history.location = Object.assign(
    history.location,
    // parse the search string using your package of choice
    { query: qs.parse(history.location.search, { ignoreQueryPrefix: true }) }
  );
};
appendQueryToLocation();
history.listen(() => {
  appendQueryToLocation();
});

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

let appStore = null;

const staticReducers = appReducers(history);

if (Config.env === 'dev') {
  appStore = createStore(
    createReducer(),
    composeWithDevTools(
      applyMiddleware(logger, sagaMiddleware, routerMiddleware(history))
    )
  );
} else {
  appStore = createStore(
    createReducer(),
    applyMiddleware(sagaMiddleware, routerMiddleware(history))
  );
}

// Add a dictionary to keep track of the registered async reducers
appStore.asyncReducers = {};

// Create an inject reducer function
// This function adds the async reducer, and creates a new combined reducer
appStore.injectReducer = (key, asyncReducer) => {
  appStore.asyncReducers[key] = asyncReducer;
  appStore.replaceReducer(createReducer(appStore.asyncReducers));
};

// Add injectSaga method to our store
appStore.injectSaga = createSagaInjector(sagaMiddleware.run, appSagas);

function createReducer(asyncReducers) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

export default appStore;
