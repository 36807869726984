/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import LoginPage from '../../modules/auth/pages/LoginPage';
import ForgotPassword from '../../modules/auth/pages/ForgotPassword';
import { AppRoute } from '../routing/AppRoute';

export default class PublicLayout extends Component<any, any> {
  match: any;

  constructor(props: any) {
    super(props);
    this.match = props.match;
  }

  componentDidMount() {
    const token = localStorage.getItem('token');
    if (token) {
      // this.props.history.push('/dashboard')
    }
  }

  render() {
    return (
      <div className="public-layout">
        <Switch>
          <AppRoute path="/forgot-password" component={ForgotPassword} />
          <AppRoute path="/login" component={LoginPage} />
        </Switch>
      </div>
    );
  }
}
