import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import ToastHelper from '../../../../../../components/ToastHelper';
import { AppButton } from '../../../../../../components/AppButton';
import Api from '../../../../../../api/Api';

function RdxPaymentDetails(props: {
  history: any;
  location: { state: { rdxPayment: any } };
}) {
  const [redirect, setRedirect] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const [payment, setPayment] = useState<any>({});

  useEffect(() => {
    _getPaymentDetails();
  }, []);

  if (redirect) {
    return <Redirect to="/" />;
  }

  const handleSubmit = async (confirmed: boolean) => {
    try {
      // eslint-disable-next-line no-unused-expressions
      confirmed ? setIsApproving(true) : setIsDeclining(true);

      if (confirmed) {
        // Ask for otp and approve transaction
        Api.call(`authenticator/v1/authorizations/${payment.id}/update`, {
          data: {
            confirm: true,
            authorizationCode: payment.authorization_code,
          },
        }).then((result: any) => {
          if (result.data && result.data.length > 0) {
            const authorization = result.data[0];
            props.history.push('/rdx-otp-code-verification', {
              authorization,
            });
          } else {
            ToastHelper.show(
              result.message || 'Something went wrong, please try again later.',
              'error'
            );
          }
        });
      } else {
        const response: any = await Api.call(
          `authenticator/v1/authorizations/${payment.id}/update`,
          {
            data: {
              confirm: confirmed,
              authorizationCode: payment.authorization_code,
            },
          }
        );

        if (response.success) {
          ToastHelper.show(
            'You declined this payment. Please return to the merchant screen.',
            'success'
          );
          setTimeout(() => {
            setRedirect(true);
          }, 1000);
        } else {
          ToastHelper.show(
            response.message || 'Something went wrong, please try again later.',
            'error'
          );
        }
      }
    } catch (error: any) {
      console.log('Error:', error);
      ToastHelper.show(
        error.message || 'Something went wrong, please try again later.',
        'error'
      );
    } finally {
      setIsApproving(false);
      setIsDeclining(false);
    }
  };

  const _getPaymentDetails = async () => {
    const { rdxPayment } = props.location.state;

    setPayment(rdxPayment);
  };

  return (
    <div className="info-container">
      {_.isEmpty(payment) ? (
        <CircularProgress className="circle-progress" size={30} />
      ) : (
        <div style={{ maxWidth: 700 }}>
          <div>
            <h2 className="bold-title">{payment.title}</h2>
            <h3 className="bold-title">{payment.description}</h3>
            <div>
              <div className="info-holder">
                <p>Status:</p>
                <p>
                  {payment['3ds_authentication_request'].status.replaceAll(
                    '_',
                    ' '
                  )}
                </p>
              </div>
              <div className="info-holder">
                <p>Merchant name:</p>
                <p>{payment['3ds_authentication_request'].merchant_name}</p>
              </div>
              <div className="info-holder">
                <p>Transaction amount:</p>
                <p>
                  {payment['3ds_authentication_request'].transaction_amount}
                </p>
              </div>
              <div className="info-holder">
                <p>Transaction currency:</p>
                <p>
                  {payment['3ds_authentication_request'].transaction_currency}
                </p>
              </div>
              <div className="info-holder">
                <p>Created at:</p>
                <p>{moment(payment.created_at).format('DD MMM YYYY, HH:mm')}</p>
              </div>
              <div className="info-holder">
                <p>Expires at:</p>
                <p>{moment(payment.expires_at).format('DD MMM YYYY, HH:mm')}</p>
              </div>
            </div>
          </div>
          {payment['3ds_authentication_request'].status === 'new' && (
            <div className="btn-info-holder">
              <AppButton
                isLoading={isDeclining}
                type="button"
                onClick={() => handleSubmit(false)}
              >
                Decline
              </AppButton>
              <div style={{ width: 10 }} />

              <AppButton
                isLoading={isApproving}
                inverted
                type="button"
                onClick={() => handleSubmit(true)}
              >
                Proceed
              </AppButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default RdxPaymentDetails;
