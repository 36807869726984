/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ForgotPasswordModel from '../models/ForgotPasswordModel';
import FormHandler from '../../../components/form/FormHandler';
import InputField from '../../../components/form/InputField';
import FormSubmitWrapper from '../../../components/form/FormSubmitWrapper';
import ToastHelper from '../../../components/ToastHelper';
import CardImage from '../../../assets/images/bbf-front-card.png';
import LogoImage from '../../../assets/images/logo.png';

export default class ForgotPassword extends Component {
  // formHandler: FormHandler;

  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new ForgotPasswordModel({
        email: ' ',
      }),
      {
        redirect: false,
      }
    );
    this.formHandler.on('submitSuccess', (data) => {
      if (data.success) {
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
      }
    });
  }

  componentDidMount() {
    this.setState((state) => ({
      ...state,
      record: {
        ...state.record,
        email: '',
        remember: false,
      },
    }));
  }

  onSubmitSuccess(data) {
    if (data.success) {
      ToastHelper.show(data.message, 'success');
    }
  }

  _getModalMessage(data) {
    if (!data.success) {
      return data.message;
    }
    return data.message;
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
    const { formHandler } = this;
    return (
      <div className="login-wrapper">
        <div className="form-holder">
          <img
            src={LogoImage}
            alt="logo"
            style={{ width: 60, height: 'auto' }}
          />
          <h2>Forgot your password?</h2>
          <p>
            Don’t worry. We’ll reset your password and
            <br /> send you a link to create a new one.
          </p>
          <div className="form-wrapper">
            <FormSubmitWrapper
              formHandler={formHandler}
              buttonClass="gold-button"
              to="/login"
              invertedBtn
              buttonText="RESET PASSWORD"
              getModalMessage={(data) => this._getModalMessage(data)}
            >
              <div className="login-input-wrapper">
                <InputField
                  className="input-group"
                  materialProps={{
                    fullWidth: true,
                  }}
                  autoComplete={this.state.record.email}
                  label="Enter E-mail"
                  name="email"
                  type="email"
                  // placeholder={'Enter E-mail'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.record.email}
                  handler={formHandler}
                />
              </div>
            </FormSubmitWrapper>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <Link
                to="/login"
                style={{
                  textDecoration: 'none',
                  fontWeight: 'lighter',
                  fontSize: 16,
                  color: '#bd9b60',
                }}
              >
                Back to login
              </Link>
            </div>
          </div>
        </div>
        <div className="img-holder">
          <img src={CardImage} alt="card" />
        </div>
      </div>
    );
  }
}
