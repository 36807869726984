/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import {
  logoutTriggerAction,
  loginTriggerAction,
} from '../../../modules/auth/AuthActions';
import ProfileImage from '../../ProfileImage';
import LogoImage from '../../../assets/images/white-logo.png';

class AppTopBar extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  logoutUser() {
    this.props.userLoggedOut(this.props.token);
    window.localStorage.removeItem('CONSENT_HASH');
    window.localStorage.removeItem('PAYMENT_HASH');
  }

  render() {
    const { profile } = this.props;

    const profileImg = <ProfileImage profile={profile} />;

    return (
      <div className="top-bar">
        <a href="/">
          <img src={LogoImage} alt="logo" className="logo" />
        </a>
        <div className="top-bar-info">
          <div className="profile-image-holder">{profileImg}</div>
          <div className="name-holder">
            <h4>{profile.fullName}</h4>
            <p>{profile.email}</p>
          </div>
          <div className="top-bar-vertical-line" />

          <div className="logout-link" onClick={() => this.logoutUser()}>
            Logout <i className="fa fa-sign-out" aria-hidden="true" />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: { auth: { token: any; profile: any } }) => {
  return {
    token: state.auth.token,
    profile: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch: {
  (arg0: { type: string; payload: {} }): void;
  (arg0: { type: string; payload: { token: any; account: any } }): void;
}) => {
  return {
    userLoggedOut: () => {
      return dispatch(logoutTriggerAction());
    },
    userLoggedIn: (token: any, account: any) => {
      return dispatch(loginTriggerAction(token, account));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppTopBar);
