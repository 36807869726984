import { fork, all, put, takeEvery } from 'redux-saga/effects';
import AuthSagas from '../modules/auth/AuthSagas';
import { APP_MOUNTED } from './AppActions';
import { MEMBER_TOKEN_LOADED } from '../modules/auth/AuthActions';
import Config from '../config';

/**
 * Logout user
 *
 * @param {Object} action
 */
function* appMounted() {
  try {
    yield Config.load();

    const token = window.localStorage.getItem('AUTH_TOKEN');

    if (token) {
      yield put({ type: MEMBER_TOKEN_LOADED, payload: token });
    }
  } catch (e) {
    console.log('Error app mount: ', e);
  }
}

export function* watchAppMounted() {
  yield takeEvery(APP_MOUNTED, appMounted);
}

export default function* allSagas() {
  yield all([fork(watchAppMounted), fork(AuthSagas)]);
}
