import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import ToastHelper from '../../../../../../components/ToastHelper';
import { AppButton } from '../../../../../../components/AppButton';
import Api from '../../../../../../api/Api';

function PaymentDetails(props: {
  match: { params: { id: any } };
  history: any;
}) {
  const [paymentHash, setPaymentHash] = useState<string | null>(null);
  const [isApproving, setIsApproving] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const [payment, setPayment] = useState<any>({});

  useEffect(() => {
    _getPaymentDetails();

    const pathname = window.location.href;
    window.localStorage.setItem('PAGE_URL', JSON.stringify(pathname));

    setPaymentHash(window.localStorage.getItem('PAYMENT_HASH'));
  }, []);

  const _getPaymentDetails = async () => {
    const { id } = props.match.params;
    const getUrl = `psd2/payment/details`;

    try {
      const result: any = await Api.call(getUrl, {
        id,
      });

      setPayment(result.data);
    } catch (e) {
      console.log('Error: ', e);
    }
  };

  const handleSubmit = async (confirmed: boolean) => {
    try {
      // eslint-disable-next-line no-unused-expressions
      confirmed ? setIsApproving(true) : setIsDeclining(true);

      if (confirmed) {
        const response: any = await Api.call(
          `sca/payment-authorization?hash=${paymentHash}`
        );

        if (response.success) {
          if (response.data.nextState.name === 'ASK_FOR_OTP') {
            props.history.push('/otp-code-verification', {
              paymentHash,
              hashForOtp: response.data.nextState.payload.hash,
              channel: response.data.nextState.payload.sentTo,
            });
          } else {
            // only for payment authorizations not for 3ds/rdx authorizations
            props.history.push('/wait-for-sca', {
              scaHash: response.data.nextState.payload.scaHash,
              authorizationKey:
                response.data.nextState.payload.authorizationKey,
            });
          }
        } else {
          ToastHelper.show(
            response.message || 'Something went wrong, please try again later.',
            'error'
          );
        }
      } else {
        const response: any = await Api.call('psd2/payment/decline', {
          id: payment.id,
        });

        if (response.success) {
          // Redirect to returned url
          window.location.replace(response.data.callback_url);
        } else {
          ToastHelper.show(
            response.message || 'Something went wrong, please try again later.',
            'error'
          );
        }
      }
    } catch (error: any) {
      console.log('Error:', error);
      ToastHelper.show(
        error.message || 'Something went wrong, please try again later.',
        'error'
      );
    } finally {
      setIsApproving(false);
      setIsDeclining(false);
    }
  };

  return (
    <div className="info-container">
      {_.isEmpty(payment) ? (
        <CircularProgress className="circle-progress" size={30} />
      ) : (
        <div style={{ maxWidth: 700 }}>
          <div>
            <h2 className="bold-title">
              {payment.status === 'pending_approval' && 'Please approve a '}
              {payment.note} in the amount of{' '}
              {parseFloat(payment.amount).toFixed(2)} {payment.currency}.
            </h2>
            <div>
              <div className="info-holder">
                <p>Status:</p>
                <p>{payment.status.replaceAll('_', ' ')}</p>
              </div>
              <div className="info-holder">
                <p>Receiver:</p>
                <p>{payment.receiverAccountName}</p>
              </div>
              <div className="info-holder">
                <p>Receiver account number:</p>
                <p>{payment.receiverAccountNumber}</p>
              </div>
              <div className="info-holder">
                <p>Street:</p>
                <p>{payment.receiverAddress.street_name}</p>
              </div>
              <div className="info-holder">
                <p>Building number:</p>
                <p>{payment.receiverAddress.building_number}</p>
              </div>
              <div className="info-holder">
                <p>Post code:</p>
                <p>{payment.receiverAddress.post_code}</p>
              </div>
              <div className="info-holder">
                <p>Town:</p>
                <p>{payment.receiverAddress.town_name}</p>
              </div>
              <div className="info-holder">
                <p>Country:</p>
                <p>{payment.receiverAddress.country}</p>
              </div>
            </div>
          </div>
          {payment.status === 'pending_approval' && (
            <div className="btn-info-holder">
              <AppButton
                isLoading={isDeclining}
                onClick={() => handleSubmit(false)}
                type="button"
              >
                Decline
              </AppButton>
              <div style={{ width: 10 }} />

              <AppButton
                isLoading={isApproving}
                inverted
                onClick={() => handleSubmit(true)}
                type="button"
              >
                Proceed
              </AppButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PaymentDetails;
