import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../../../../../api/Api';

function AuthorizationPaymentList() {
  const [payments, setPayment] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    _getAuthorizationPayments();
  }, []);

  const _renderPaymentItem = (item: any) => {
    if (item['3ds_authentication_request']) {
      return (
        <Link
          key={item.id}
          to={{
            pathname: `/rdx-payment-details/${item.id}`,
            state: { rdxPayment: item },
          }}
          style={{
            textDecoration: 'none',
          }}
        >
          <div className="consent-item">
            <div>
              <p style={{ fontSize: 16 }}>
                {item['3ds_authentication_request'].merchant_name}
              </p>
              <p style={{ fontSize: 12 }}>{item.title}</p>
            </div>
            <span>Click to approve</span>
          </div>
        </Link>
      );
    }
    return (
      <Link
        key={item.id}
        to={`/payment-details/${item.payment.paymentId}`}
        onClick={() => {
          if (item.payment.paymentHash) {
            window.localStorage.setItem(
              'PAYMENT_HASH',
              item.payment.paymentHash
            );
          }
        }}
        style={{
          textDecoration: 'none',
        }}
      >
        <div className="consent-item">
          <div>
            <p style={{ fontSize: 16 }}>{item.payment.receiverAccountName}</p>
            <p style={{ fontSize: 12 }}>{item.payment.note}</p>
          </div>
          <span>Click to approve</span>
        </div>
      </Link>
    );
  };

  const _getAuthorizationPayments = async () => {
    setLoading(true);

    const getUrl = `authenticator/v1/authorizations`;

    try {
      const result: any = await Api.call(getUrl, {
        filterType: 'payment',
        // type: '3ds_authentication', this is filter only for 3ds authentications
      });

      if (result.data) {
        setPayment(result.data);
        setLoading(false);
      }
    } catch (e) {
      console.log('Error: ', e);
    }
  };

  return (
    <div>
      {!loading && (
        <ul className="list-ul">
          {payments.length > 0 ? (
            payments.map((item) => {
              return _renderPaymentItem(item);
            })
          ) : (
            <div className="no-items" style={{ margin: '20px 0' }}>
              <em>A authorization payment list will be displayed here</em>
            </div>
          )}
        </ul>
      )}
    </div>
  );
}

export default AuthorizationPaymentList;
