import BaseModel from '../../../../../model/BaseModel';
import Api from '../../../../../api/Api';

export default class ConsentModel extends BaseModel {
  getListUrl() {
    return 'psd2/consent/list';
  }

  async revokeConsent(id) {
    const response = await Api.call(`psd2/consent/revoke`, {
      id,
    });
    return response;
  }
}
