import React from 'react';
import Pagination from 'react-js-pagination';

export default class AppListPagination extends React.PureComponent {
  render() {
    if (!this.props.paginate) return null;

    if (!this.props.pagination || this.props.pagination.total_pages === 0)
      return null;

    const pages = this.props.pagination;

    if (pages.total_pages === 1) return null;

    return (
      <Pagination
        hideFirstLastPages
        innerClass="pagination pull-right"
        itemClass="page-item"
        itemClassFirst="page-item"
        itemClassNext="page-item"
        itemClassPrev="page-item"
        linkClass="page-link"
        linkClassFirst="page-link"
        activePage={this.props.pagination.page}
        itemsCountPerPage={pages.limit}
        totalItemsCount={pages.total_items}
        pageRangeDisplayed={10}
        onChange={(pageNumber) => this.props.onPageChanged(pageNumber)}
      />
    );
  }
}

AppListPagination.defaultProps = {
  onPageChanged: (page) => console.log(`changing page to ${page}`),
  paginate: true,
};
