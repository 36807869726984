import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import ConsentModel from '../model/ConsentModel';
import ToastHelper from '../../../../../components/ToastHelper';
import { AppButton } from '../../../../../components/AppButton';
import Api from '../../../../../api/Api';

function ConsentDetails(props: {
  history: any;
  match: { params: { id: any } };
}) {
  const [consentHash, setConsentHash] = useState<string | null>(null);
  const [consent, setConsent] = useState<any>({});
  const [isApproving, setIsApproving] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const [revokeLoading, setRevokeLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    _getConsentDetails();
    const pathname = window.location.href;
    window.localStorage.setItem('PAGE_URL', JSON.stringify(pathname));

    setConsentHash(window.localStorage.getItem('CONSENT_HASH'));
  }, []);

  const handleRevokeButton = async (id: string) => {
    setRevokeLoading(true);
    const response: any = await new ConsentModel().revokeConsent(id);

    if (response.success) {
      ToastHelper.show('Consent successfully revoked!', 'success');
      setRevokeLoading(false);

      setTimeout(() => {
        setRedirect(true);
      }, 1000);
    } else {
      ToastHelper.show(
        'Something went wrong, please try again later.',
        'error'
      );
      setRevokeLoading(false);
    }
  };

  const handleSubmit = async (confirmed: boolean) => {
    try {
      // eslint-disable-next-line no-unused-expressions
      confirmed ? setIsApproving(true) : setIsDeclining(true);

      if (confirmed) {
        const response: any = await Api.call(
          `sca/consent-token-authorization?hash=${consentHash}`
        );

        if (response.success) {
          if (response.data.nextState.name === 'ASK_FOR_OTP') {
            props.history.push('/otp-code-verification', {
              consentHash,
              hashForOtp: response.data.nextState.payload.hash,
              channel: response.data.nextState.payload.sentTo,
            });
          } else {
            // SCA flow
            props.history.push('/wait-for-sca', {
              scaHash: response.data.nextState.payload.scaHash,
              authorizationKey:
                response.data.nextState.payload.authorizationKey,
            });
          }
        } else {
          ToastHelper.show(
            response.message || 'Something went wrong, please try again later.',
            'error'
          );
        }
      } else {
        const response: any = await Api.call('psd2/consent/decline', {
          hash: consentHash,
        });

        if (response.success) {
          // Redirect to returned url
          window.location.replace(response.data.cancel_url);
        } else {
          ToastHelper.show(
            response.message || 'Something went wrong, please try again later.',
            'error'
          );
        }
      }
    } catch (error: any) {
      console.log('Error:', error);
      ToastHelper.show(
        error.message || 'Something went wrong, please try again later.',
        'error'
      );
    } finally {
      setIsApproving(false);
      setIsDeclining(false);
    }
  };

  const _getConsentDetails = async () => {
    const { id } = props.match.params;
    const getUrl = `psd2/consent/details`;

    try {
      const result: any = await Api.call(getUrl, {
        id,
      });

      setConsent(result.data);
    } catch (e) {
      console.log('Error: ', e);
    }
  };

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <div className="info-container">
      {_.isEmpty(consent) ? (
        <CircularProgress className="circle-progress" size={30} />
      ) : (
        <div>
          {consent.status === 'unconfirmed' || consent.status === 'pending' ? (
            <div style={{ maxWidth: 700 }}>
              <div>
                <p className="consent-text">
                  {consent.tppName} is requesting your approval to access your
                  Bourgeois Bohème account information and to process data on
                  its behalf. This data includes your transaction data and
                  account information, including the balance.
                </p>
                {consent.validUntil ? (
                  <p className="consent-text">
                    {consent.appName}
                    will access the information from your account until:{' '}
                    {moment(consent.validUntil, 'DD MMM YYYY')}.
                  </p>
                ) : null}
                <p className="consent-text">
                  By proceeding below, you accept that you are duly authorized
                  signatory, and agree to grant read access to your Bourgeois
                  Bohème account.
                </p>
              </div>
              <div className="btn-info-holder">
                <AppButton
                  isLoading={isDeclining}
                  type="button"
                  onClick={() => handleSubmit(false)}
                >
                  Decline
                </AppButton>
                <div style={{ width: 10 }} />

                <AppButton
                  isLoading={isApproving}
                  inverted
                  type="button"
                  onClick={() => handleSubmit(true)}
                >
                  Proceed
                </AppButton>
              </div>
            </div>
          ) : (
            <div>
              <h2 className="bold-title">{consent.tppName}</h2>
              <div
                style={{
                  minHeight: consent.status === 'confirmed' ? 150 : 'auto',
                }}
              >
                <p className="consent-text">Status: {consent.status}</p>
                {consent.created && (
                  <p className="consent-text">
                    Created at:{' '}
                    {moment(consent.created).format('DD MMM YYYY, HH:mm')}
                  </p>
                )}
                {consent.tokenExpiresAt && consent.status !== 'revoked' && (
                  <p className="consent-text">
                    Expires at:{' '}
                    {moment(consent.tokenExpiresAt).format(
                      'DD MMM YYYY, HH:mm'
                    )}
                  </p>
                )}
              </div>
              {consent.status === 'confirmed' && (
                <AppButton
                  inverted
                  type="button"
                  isLoading={revokeLoading}
                  onClick={() => handleRevokeButton(consent.id)}
                >
                  Revoke
                </AppButton>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ConsentDetails;
