/* eslint-disable default-case */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable default-param-last */
import { connectRouter } from 'connected-react-router';
import AuthReducers from '../modules/auth/AuthReducers';
import {
  MEMBER_LOGIN_TRIGGER,
  MEMBER_TOKEN_LOADED,
} from '../modules/auth/AuthActions';
import { APP_MOUNTED } from './AppActions';

function network(state = null, action) {
  const newState = {
    error: null,
  };

  switch (action.type) {
    case 'NETWORK_ERROR':
      newState.error = action.payload.message;
      break;
    default:
  }
  return newState;
}

function ready(state = false, action) {
  switch (action.type) {
    case MEMBER_LOGIN_TRIGGER:
      return true;
    case MEMBER_TOKEN_LOADED:
      return true;
    case APP_MOUNTED:
      return true;
  }
  return state;
}

const createRootReducer = (history) => ({
  router: connectRouter(history),
  ready,
  network,
  auth: AuthReducers,
});

export default createRootReducer;
