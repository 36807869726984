import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ConsentList from '../components/psd2-consents/pages/ConsentList';
import AuthorizationPaymentList from '../components/psd2-consents/pages/payments/AuthorizationPaymentList';

function Dashboard() {
  const [isConsent, setIsConsent] = useState<string | null>(null);
  const [isPayment, setIsPayment] = useState<string | null>(null);
  useEffect(() => {
    const pathname = window.localStorage.getItem('PAGE_URL');
    if (pathname && JSON.parse(pathname).includes('consent-details')) {
      window.localStorage.removeItem('CONSENT_HASH');
      window.localStorage.removeItem('PAGE_URL');
    } else if (pathname && JSON.parse(pathname).includes('payment-details')) {
      window.localStorage.removeItem('PAYMENT_HASH');
      window.localStorage.removeItem('PAGE_URL');
    }

    setIsConsent(window.localStorage.getItem('CONSENT_HASH'));
    setIsPayment(window.localStorage.getItem('PAYMENT_HASH'));
  }, []);

  return (
    <div className="appContainer">
      {isConsent || isPayment ? (
        <div className="progress-wrapper">
          <CircularProgress
            className="circle-progress"
            size={30}
            style={{ margin: 20 }}
          />
        </div>
      ) : (
        <>
          <div className="list-container">
            <h2 style={{ paddingBottom: 20 }}>Consent List</h2>
            <ConsentList />
          </div>
          <div className="list-container">
            <h2 style={{ paddingBottom: 20 }}>Authorization List</h2>
            <AuthorizationPaymentList />
          </div>
        </>
      )}
    </div>
  );
}

export default Dashboard;
