import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

interface ButtonProps {
  isLoading?: boolean;
  inverted?: boolean;
  customStyle?: any;
  type?: any;
  onClick?: () => void;
}

export class AppButton extends React.Component<ButtonProps> {
  _getButtonContent(isLoading: boolean | undefined) {
    if (isLoading) {
      return (
        <CircularProgress
          className={`circle-progress ${this.props.inverted ? 'light' : ''}`}
          size={30}
        />
      );
    }

    return this.props.children;
  }

  render() {
    const { inverted, isLoading, ...rest } = this.props;
    return (
      <div
        className={`gradient-button ${inverted ? 'gradient-btn-custom' : ''}`}
        style={this.props.customStyle}
      >
        <Button
          {...rest}
          variant="contained"
          className={inverted ? 'custom-inverted-btn' : 'custom-btn'}
        >
          {this._getButtonContent(isLoading)}
        </Button>
      </div>
    );
  }
}

export default AppButton;
