import { takeEvery, fork, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  MEMBER_LOGIN_TRIGGER,
  MEMBER_LOGOUT_TRIGGER,
  MEMBER_LOGOUT_DONE,
  MEMBER_AUTH_REFRESH,
  MEMBER_TOKEN_LOADED,
  MEMBER_AUTH_LOADED,
  MEMBER_AUTH_REDIRECTED,
} from './AuthActions';
import Api from '../../api/Api';

/**
 * Logout user
 *
 * @param {Object} action
 */
function* loginMember(action) {
  try {
    window.localStorage.setItem('AUTH_TOKEN', action.payload.token);

    yield put({ type: MEMBER_AUTH_LOADED, payload: action.payload.account });

    const CONSENT_HASH = window.localStorage.getItem('CONSENT_HASH');
    const PAYMENT_HASH = window.localStorage.getItem('PAYMENT_HASH');

    if (CONSENT_HASH) {
      const result = yield Api.call(`psd2/consent/details`, {
        hash: CONSENT_HASH,
      });
      yield put(push(`/consent-details/${result.data.id}`));
    } else if (PAYMENT_HASH) {
      const resultPayment = yield Api.call(`psd2/payment/details`, {
        hash: PAYMENT_HASH,
      });
      yield put(push(`/payment-details/${resultPayment.data.id}`));
    } else {
      yield put(push('/'));
    }

    yield put({ type: MEMBER_AUTH_REDIRECTED });
  } catch (e) {
    console.log('Login error: ', e);
  }
}

function* logoutMember(action) {
  try {
    window.localStorage.removeItem('AUTH_TOKEN', action.payload.token);
  } catch (e) {
    console.log('Logout error: ', e);
  }

  yield put({ type: MEMBER_LOGOUT_DONE });
}

function* refreshAuth() {
  try {
    const token = window.localStorage.getItem('AUTH_TOKEN');

    if (token) {
      yield put({ type: MEMBER_TOKEN_LOADED, payload: token });
      const result = yield Api.call('auth/me');

      if (result.success) {
        yield put({ type: MEMBER_AUTH_LOADED, payload: result.data });
      }
    }
  } catch (e) {
    console.log('Refresh auth error: ', e);
  }
}

export function* watchMemberLogin() {
  yield takeEvery(MEMBER_LOGIN_TRIGGER, loginMember);
}

export function* watchMemberLogout() {
  yield takeEvery(MEMBER_LOGOUT_TRIGGER, logoutMember);
}

export function* watchRefreshAuth() {
  yield takeEvery(MEMBER_AUTH_REFRESH, refreshAuth);
}

export default function* authSagas() {
  // yield all([
  yield fork(watchMemberLogin);
  yield fork(watchMemberLogout);
  yield fork(watchRefreshAuth);
  // ]);
}
