/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import LoginModel from '../models/LoginModel';
import { loginTriggerAction } from '../AuthActions';
import FormHandler from '../../../components/form/FormHandler';
import InputField from '../../../components/form/InputField';
import FormSubmitWrapper from '../../../components/form/FormSubmitWrapper';
import CardImage from '../../../assets/images/bbf-front-card.png';
import LogoImage from '../../../assets/images/logo.png';

class LoginPage extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new LoginModel({
        email: '',
        password: '',
        hash: '',
      }),
      {
        redirect: false,
      }
    );
    this.formHandler.on('submitSuccess', (data) => {
      if (data.success) {
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 3000);
      }
    });
  }

  componentDidMount() {
    const queryParameters = new URLSearchParams(window.location.search);
    const hash = queryParameters.get('hash');

    this.setState((state) => ({
      ...state,
      record: {
        ...state.record,
        email: '',
        password: '',
        hash: window.location.href.includes('consent-token-authorization')
          ? hash
          : '',
      },
    }));
  }

  onSubmitSuccess(data) {
    if (data.success && data.data.token) {
      this.props.userLoggedIn(data.data.token, data.data.account);
    }
  }

  _getModalMessage(data) {
    if (!data.success) {
      return data.message;
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    const { formHandler } = this;

    const queryParameters = new URLSearchParams(window.location.search);
    const hash = queryParameters.get('hash');

    if (window.location.href.includes('payment-authorization')) {
      window.localStorage.setItem('PAYMENT_HASH', hash);
    } else if (window.location.href.includes('consent-token-authorization')) {
      window.localStorage.setItem('CONSENT_HASH', hash);
    }

    return (
      <div className="login-wrapper">
        <div className="form-holder">
          <img
            src={LogoImage}
            alt="logo"
            style={{ width: 60, height: 'auto' }}
          />
          <h2>LOGIN</h2>
          <div className="form-wrapper">
            <FormSubmitWrapper
              formHandler={formHandler}
              buttonClass="gold-button"
              to="/"
              invertedBtn
              buttonText="LOGIN"
              getModalMessage={(data) => this._getModalMessage(data)}
            >
              <div className="login-input-wrapper">
                <InputField
                  className="input-group"
                  materialProps={{
                    fullWidth: true,
                  }}
                  autoComplete={this.state.record.email}
                  label="E-mail"
                  name="email"
                  type="email"
                  // placeholder={'E-mail'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.record.email}
                  handler={formHandler}
                />
              </div>
              <div className="login-input-wrapper">
                <InputField
                  className="input-group"
                  materialProps={{
                    fullWidth: true,
                  }}
                  autoComplete={this.state.record.password}
                  label="Password"
                  type="password"
                  name="password"
                  // placeholder={'Password'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.record.password}
                  handler={formHandler}
                />
              </div>
            </FormSubmitWrapper>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
                marginBottom: 50,
              }}
            >
              <Link
                to="/forgot-password"
                style={{
                  textDecoration: 'none',
                  fontWeight: 'lighter',
                  fontSize: 16,
                  color: '#bd9b60',
                }}
              >
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
        <div className="img-holder">
          <img src={CardImage} alt="card" />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userLoggedIn: (token, account) => {
      return dispatch(loginTriggerAction(token, account));
    },
  };
};

export default connect(null, mapDispatchToProps)(LoginPage);
