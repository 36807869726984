import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../../redux/AppStore';

export class AppRouter extends React.PureComponent {
  render() {
    return (
      <ConnectedRouter history={history}>{this.props.children}</ConnectedRouter>
    );
  }
}

export default AppRouter;
