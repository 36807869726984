/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable prefer-const */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

/**
 * Map state to props
 *
 * @param {Object} state
 */
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

/**
 * Map dispatch to props
 *
 * @param {function} dispatch
 */
const mapDispatchToProps = (dispatch) => {
  return {
    redirect: (token) => dispatch(push(token)),
  };
};

export class AppRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      component: null,
    };
  }

  render() {
    // Prevent authenticated to access public routes
    if (this.props.unAuthenticatedOnly && this.props.token) {
      return <Redirect to="/" />;
    }

    // Prevent nonauthenticated to access private routes
    if (this.props.authenticatedOnly && !this.props.token) {
      return <Redirect to="/login" />;
    }

    let { component: Component, layout: Layout, ...rest } = this.props;

    if (this.props.load) {
      const { load } = this.props;
      Component = function () {
        return <DynamicLoad load={load} />;
      };
    }

    if (Layout) {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      );
    }
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
}

class DynamicLoad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      component: null,
    };
  }

  componentDidMount() {
    if (this.props.load && !this.state.component) {
      this.props.load().then((component) => {
        this.setState({
          component: component.default ? component.default : component,
        });
      });
    }
  }

  render() {
    if (this.state.component) {
      const Component = this.state.component;
      return <Component />;
    }
    return <div>Loading</div>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRoute);
