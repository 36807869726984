import BaseModel from './BaseModel';
import appStore from '../redux/AppStore';

export default class ConfigModel extends BaseModel {
  /**
   * Get table name
   */
  getTableName() {
    return 'config';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'app/config';
  }

  dispatchConfigUpdate(config) {
    appStore.dispatch({
      type: 'CONFIG_UPDATE',
      payload: config,
    });
  }
}
