import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/AppStore';
import AppContainer from './AppContainer';
import AppToastAlert from './components/AppToastAlert';
import './styles/style.scss';
import 'font-awesome/css/font-awesome.min.css';

function App() {
  return (
    <Provider store={store}>
      <AppContainer />
      <AppToastAlert />
    </Provider>
  );
}

export default App;
